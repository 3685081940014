import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/SEO"
import Hero from "@components/HomePage/Hero"
import Partners from "@components/HomePage/Partners"
import ClimateRisks from "@components/HomePage/ClimateRisks"
import WhyClimateCheck from "@components/HomePage/WhyClimateCheck"
import StayUpToDate from "@components/AboutUsPage/StayUpToDate"
import InThePress from "@components/HomePage/InThePress"
import RiskAssessment from "@components/global/RiskAssessment"
import Footer from "@components/global/Footer"

import "@styles/Components/homePage.scss"
import loadable from "@loadable/component"
import LocationWrapper from "../components/LocationWrapper"

const CorporativeHeader = loadable(() =>
  import("@components/global/CorporativeHeader")
)
const OurMethodologies = loadable(() =>
  import("@components/HomePage/OurMethodologies")
)
const Solutions = loadable(() => import("@components/HomePage/Solutions"))
const MitigationOverview = loadable(() =>
  import("@components/HomePage/MitigationOverview")
)
const OurTeam = loadable(() => import("@components/HomePage/OurTeam"))
const MainPost = loadable(() => import("@components/HomePage/MainPost"))

const OurMission = loadable(() => import("@components/HomePage/OurMission"))
const RiskOverview = loadable(() => import("@components/HomePage/RiskOverview"))
const CorporativeFooter = loadable(() =>
  import("@components/global/CorporativeFooter")
)
const MainPosts = loadable(() => import("@components/HomePage/MainPosts"))

const IndexPage = ({
  data: {
    seo,
    homeHero1,
    climateRisksCard1Image,
    climateRisksCard2Image,
    climateRisksCard3Image,
    climateRisksCard4Image,
    climateRisksCard5Image,
    climateRisksCard6Image,
    climateRisksCard7Image,
    climateRisksCard8Image,
    climateRisksCard9Image,
    climateRisksCard10Image,
    solutionsBackgroundImage,
    screenshot,
    portfolioImgOne,
    mitigationOverviewBackgroundImage,
    ourTeamCarouselImg1,
    ourTeamCarouselImg2,
    dataDeliveryImg,
    allSanityPressMentions,
    allSanityPost,
    homeHero2,
    homeHero3,
    missionImg,
    riskImage,
    mitigationImg,
    bgPressMentions,
    allSanityCustomers,
  },
}) => {
  const customSeo = {
    title: seo?.seo?.title || "Home",
    link: "https://climatecheck.com",
    description:
      seo?.seo?.description ||
      "One step ahead of the climate crisis. We bring the realities of climate change to your doorstep with a proprietary rating️ and report for any US property.",
    ...seo?.seo,
  }

  return (
    <LocationWrapper>
      <SEO seo={customSeo} />
      <CorporativeHeader />
      <Hero images={[homeHero1]} />
      <Partners />
      <OurMethodologies />
      <ClimateRisks
        card1Image={climateRisksCard1Image}
        card2Image={climateRisksCard2Image}
        card3Image={climateRisksCard3Image}
        card4Image={climateRisksCard4Image}
        card5Image={climateRisksCard5Image}
        card6Image={climateRisksCard6Image}
        card7Image={climateRisksCard7Image}
        card8Image={climateRisksCard8Image}
        card9Image={climateRisksCard9Image}
        card10Image={climateRisksCard10Image}
      />
      <Solutions
        backgroundImage={solutionsBackgroundImage}
        screenshot={screenshot}
      />
      <MitigationOverview
        backgroundImage={mitigationOverviewBackgroundImage}
        mitigationImg={portfolioImgOne}
      />
      <OurTeam
        ourTeamImg1={ourTeamCarouselImg1}
        ourTeamImg2={ourTeamCarouselImg2}
      />
      <WhyClimateCheck />
      <MainPost
        bgPressMentions={dataDeliveryImg}
        pressMentions={allSanityPressMentions.edges}
      />
      <StayUpToDate posts={allSanityPost.edges} />
      <InThePress />
      <RiskAssessment />
      <Footer />
    </LocationWrapper>
  )
}

export default IndexPage

export const query = graphql`
  query {
    seo: sanityPagesSeo(slug: { current: { eq: "/" } }) {
      seo {
        title
        keywords
        description
      }
    }
    homeHero1: file(relativePath: { eq: "img/home-hero-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    homeHero2: file(relativePath: { eq: "img-min/Image-Hero-2.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    homeHero3: file(relativePath: { eq: "img-min/Image-Hero-3.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    missionImg: file(relativePath: { eq: "img/ourmission.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    riskImage: file(relativePath: { eq: "img/risk-homepage.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    screenshot: file(relativePath: { eq: "img/solutionsImage.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    mitigationOverviewBackgroundImage: file(
      relativePath: { eq: "img/solutions-bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourTeamCarouselImg1: file(
      relativePath: { eq: "img/ourTeam-carouselImg-1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    ourTeamCarouselImg2: file(
      relativePath: { eq: "img/ourTeam-carouselImg-2.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard1Image: file(
      relativePath: { eq: "img/climateRisks-precipitation.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard2Image: file(
      relativePath: { eq: "img/climateRisks-drought.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard3Image: file(
      relativePath: { eq: "img/climateRisks-extreme-heat.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard4Image: file(
      relativePath: { eq: "img/climateRisks-wildfire.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard5Image: file(
      relativePath: { eq: "img/climateRisks-seismic.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard6Image: file(
      relativePath: { eq: "img/climateRisks-extreme-wind.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard7Image: file(
      relativePath: { eq: "img/climateRisks-flood-storm-surge.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard8Image: file(
      relativePath: { eq: "img/climateRisks-flood-sea-level-rise.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard9Image: file(
      relativePath: { eq: "img/climateRisks-flood-pluvial-fluvial.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    climateRisksCard10Image: file(
      relativePath: { eq: "img/climateRisks-hurricane.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    solutionsBackgroundImage: file(relativePath: { eq: "img/bg-topo.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    dataDeliveryImg: file(relativePath: { eq: "img/carousel-home.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    portfolioImgOne: file(relativePath: { eq: "img/portfolio_content.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    mitigationImg: file(relativePath: { eq: "img-min/image-00001.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    allSanityPressMentions(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          url
          _id
          customImage {
            altText
            image {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          description
        }
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          categories {
            title
          }
          customImage {
            image {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                description
              }
            }
          }
          body {
            children {
              text
            }
          }
          author {
            name
          }
          publishedAt(formatString: "YYYY-MM-DD")
        }
      }
    }
    allSanityCustomers(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          url
          _id
          customImage {
            altText
            image {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
