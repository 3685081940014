import React from "react"
import { getLocation } from "@services/MapBox"
import LocationContext from "../../locationContext/locationContext"

const LocationWrapper = ({ children }) => {
  const [userLocation, setUserLocation] = React.useState({})

  React.useEffect(() => {
    getLocation()
      .then(res => {
        setUserLocation(res)
      })
      .catch(err => {
        return err
      })
  }, [])

  return (
    <LocationContext.Provider value={userLocation}>
      {children}
    </LocationContext.Provider>
  )
}

export default LocationWrapper
