import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Text } from "@blueprintjs/core"
import InputSearch from "@components/global/InputSearch"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const HomeHero = ({ images }) => {
  const imageIndex = Math.floor(Math.random() * images.length)
  const image = convertToBgImage(getImage(images[imageIndex]))

  return (
    <BackgroundImage {...image}>
      <section className="home-hero">
        <div className="container">
          <Text tagName="h1">
            Complete and Reliable Climate Risk Data Solutions
          </Text>
          <Text tagName="p">How will climate change affect your property?</Text>
          <InputSearch />
        </div>
      </section>
    </BackgroundImage>
  )
}

export default HomeHero
