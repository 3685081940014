import React from "react"
import { Link } from "gatsby"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const WhyClimateCheck = () => {
  return (
    <section className="why-climate-check">
      <div className="container">
        <h2>Why ClimateCheck</h2>
        <div className="why-climate-check__cards">
          <div className="why-climate-check__card">
            <h3>Reliable</h3>
            <p>
              Rigorous and transparent methodology backed by published climate
              science.
            </p>
          </div>
          <div className="why-climate-check__card">
            <h3>Complete</h3>
            <p>
              Complete geographic coverage for all climate hazards. Historic,
              current, and future projections include multiple climate
              scenarios.
            </p>
          </div>
          <div className="why-climate-check__card">
            <h3>Actionable</h3>
            <p>Easy to use, easy to interpret, easy to integrate.</p>
          </div>
        </div>
        <Link to="/solutions" className="link-btn">
          Learn More <CTARightArrow />
        </Link>
      </div>
    </section>
  )
}

export default WhyClimateCheck
